<template>
  <section v-if="userHasMedia" class="photobooth-gallery section py-5 px-0">
    <b-field :label="$t('system.gallery')" class="px-5"></b-field>

    <vue-glide
      :perView="1"
      :peek="20"
      :gap="5"
      :key="sliderId"
      :swipeThreshold="enableSwipe"
    >
      <!-- Slides skeleton mientras carga -->
      <template v-if="loading">
        <vue-glide-slide>
          <b-skeleton />
        </vue-glide-slide>
        <vue-glide-slide>
          <b-skeleton />
        </vue-glide-slide>
      </template>
      <!-- Slides de videos posta -->
      <template v-else>
        <!-- User Media -->
        <vue-glide-slide v-for="media in userMedia" :key="media.id">
          <template v-if="media.converting">
            <div
              class="backgroundThumbnail"
              :style="{
                backgroundImage: media.thumbnail?.url
                  ? `url(${media.thumbnail.url})`
                  : 'none',
              }"
            >
              <div class="converting">
                <div>{{ $t("system.vieoProccesing") }}</div>
                <d-icon
                  icon="FaSpinnerThird"
                  extraclass="fa-rotate"
                  size="is-medium"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <!-- <video :src="`${video.url}#t=0.1`" preload="auto" @click="FullscreenVideo(video)" disableRemotePlayback muted playsinline loop></video> -->
            <template v-if="media.thumbnail">
              <img
                :src="media.thumbnail.url"
                @click="FullscreenVideo(media.video)"
              />
            </template>
            <template v-else>
              <video
                :src="`${media.video.url}#t=0.1`"
                preload="auto"
                @click="FullscreenVideo(media.video)"
                disableRemotePlayback
                muted
                playsinline
                loop
              ></video>
            </template>
            <div v-if="media.video?.url" class="playIcon">
              <d-icon icon="FaPlay" size="is-large" style="color: white" />
            </div>
          </template>
        </vue-glide-slide>
        <!-- Videos -->
        <vue-glide-slide v-for="video in videos" :key="video.id">
          <template v-if="video.converting">
            <div class="converting">
              <div>{{ $t("system.videoAlmostReady") }}</div>
              <d-icon
                icon="FaSpinnerThird"
                extraclass="fa-rotate"
                size="is-medium"
              />
            </div>
          </template>
          <template v-else>
            <video
              :src="`${video.url}#t=0.1`"
              preload="auto"
              @click="FullscreenVideo(video)"
              disableRemotePlayback
              muted
              playsinline
              loop
            ></video>
          </template>
        </vue-glide-slide>
        <!-- Photos -->
        <vue-glide-slide v-for="photo in photos" :key="photo.id">
          <img
            :src="photo.sizes.mobile.url ?? photo.url"
            @click="FullscreenImage(photo)"
          />
        </vue-glide-slide>
      </template>
    </vue-glide>

    <!-- Fullscreen video modal -->
    <b-modal v-model="isVideoCardModalActive">
      <div class="card" v-if="fullscreenVideo != null">
        <div class="card-image p-2">
          <figure class="image">
            <video disableRemotePlayback autoplay muted loop playsinline>
              <source
                :src="fullscreenVideo.url"
                :type="fullscreenVideo.mimeType"
              />
            </video>
          </figure>
        </div>
        <div class="card-content pt-2 pb-4">
          <p class="has-text-centered">
            <!-- Share Video -->
            <b-button
              type="is-primary"
              size="is-medium"
              @click="shareMedia(fullscreenVideo.url, 'video')"
              class="mr-2"
              :loading="fetchingMediaShare"
            >
              <d-icon icon="FaShareNodes" size="is-medium" />
            </b-button>
            <!-- Download video -->
            <a
              :href="fullscreenVideo.url"
              download
              class="button is-medium is-outlined ml-2"
              _target="blank"
              @click="AnalyticsDownloadMedia('video')"
            >
              <d-icon icon="FaDownload" size="is-medium" />
            </a>
          </p>
        </div>
      </div>
    </b-modal>

    <!-- Fullscreen image modal -->
    <b-modal v-model="isPhotoCardModalActive">
      <div class="card" v-if="fullscreenImage != null">
        <div class="card-image">
          <figure class="image">
            <img
              :src="fullscreenImage.sizes.mobile.url ?? fullscreenImage.url"
            />
          </figure>
        </div>
        <div class="card-content pt-2 pb-4">
          <p class="has-text-centered">
            <!-- Share Image -->
            <b-button
              type="is-primary"
              size="is-medium"
              @click="shareMedia(fullscreenImage.url, 'image')()"
              class="mr-2"
              :loading="fetchingMediaShare"
            >
              <d-icon icon="FaShareNodes" size="is-medium" />
            </b-button>
            <!-- Download image -->
            <a
              :href="fullscreenImage.url"
              download
              class="button is-medium is-outlined ml-2"
              _target="blank"
              @click="AnalyticsDownloadMedia('image')"
            >
              <d-icon icon="FaDownload" size="is-medium" />
            </a>
          </p>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import "vue-glide-js/dist/vue-glide.css";
import { Glide, GlideSlide } from "vue-glide-js";
import axios from "axios";

export default {
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {},
  data() {
    return {
      sliderId: 0,
      loading: true,
      userMedia: [],
      photos: [], // deprecated. TODO borrar
      videos: [], // deprecated. TODO borrar
      fullscreenImage: null,
      fullscreenVideo: null,
      isPhotoCardModalActive: false,
      isVideoCardModalActive: false,
      fetchingMediaShare: false,
    };
  },
  computed: {
    userHasMedia() {
      return (
        this.photos.length > 0 ||
        this.videos.length > 0 ||
        this.userMedia.length > 0
      );
    },
    enableSwipe() {
      const defaultValue = 80;
      if (this.loading) return 0;
      if (
        this.photos.length > 1 ||
        this.videos.length > 1 ||
        this.userMedia.length > 1
      )
        return defaultValue;
      return 0;
    },
  },
  methods: {
    AnalyticsDownloadMedia(mediaType) {
      this.$TrackUserEvent(`Download Video ${mediaType}`);
      console.log(`Analytics Download ${mediaType}`);
    },
    FullscreenVideo(video) {
      console.log("Fullscreen Video", video);
      this.fullscreenVideo = video;
      this.isVideoCardModalActive = true;
    },
    async shareMedia(mediaUrl, mediaType = "image") {
      try {
        console.log(`share ${mediaType}`);
        this.fetchingMediaShare = true;
        const response = await fetch(mediaUrl);
        if (!response.ok) {
          this.fetchingMediaShare = false;
          throw new Error("Network response was not ok");
        }

        const mediaBlob = await response.blob();
        console.log("Blob type:", mediaBlob.type);

        const fileName = `${mediaType}.${
          mediaType === "image" ? "png" : "mp4"
        }`;
        const fileType = mediaType === "image" ? "image/png" : "video/mp4";
        const mediaFile = new File([mediaBlob], fileName, { type: fileType });
        console.log("File:", mediaFile);

        const shareData = {
          files: [mediaFile],
        };
        console.log(shareData);

        if (navigator.share && typeof navigator.share === "function") {
          if (navigator.canShare && navigator.canShare(shareData)) {
            this.fetchingMediaShare = false;
            await navigator.share(shareData);
            this.$TrackUserEvent(`Share ${mediaType}`);
            console.log(`${mediaType} shared successfully`);
          } else {
            console.error("File sharing is not supported in this browser.");
            this.fetchingMediaShare = false;
          }
        } else {
          // Fallback for browsers that do not support Web Share API
          const reader = new FileReader();
          reader.onload = function (event) {
            const dataUrl = event.target.result;
            console.log("Data URL:", dataUrl);
            // Handle sharing or provide fallback for sharing on non-supported browsers
          };
          reader.readAsDataURL(mediaBlob);
          this.fetchingMediaShare = false;
        }
      } catch (error) {
        console.error(`Error fetching and sharing ${mediaType}:`, error);
        this.fetchingMediaShare = false;
      }
    },
    isIOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    FullscreenImage(image) {
      console.log("Fullscreen Image")
      this.fullscreenImage = image;
      this.isPhotoCardModalActive = true;
    },
    async FetchUserMedia(openLastMedia = false) {
      const res = await axios.get(
        process.env.VUE_APP_API_BASE +
          `/api/usermedia?where[users][equals]=${this.$store.state.user.profile.id}`
      );
      if (res.status == 200) {
        this.userMedia = res.data.docs;
        this.sliderId++; // para forzar recargar
        if (openLastMedia && this.userMedia.length > 0) {
          if (this.userMedia[0].converting) {
            // Si el ultimo video está en conversion, no lo abro
            return;
          }
          this.FullscreenVideo(this.userMedia[0].video);
        }
      } else {
        console.warn("Error al cargar las fotos", res);
      }
    },
    async FetchVideos(openLastVideo = false) {
      const resVideo = await axios.get(
        process.env.VUE_APP_API_BASE +
          `/api/uservideos?where[users][equals]=${this.$store.state.user.profile.id}`
      );
      if (resVideo.status == 200) {
        this.videos = resVideo.data.docs;
        this.sliderId++; // para forzar recargar
        if (openLastVideo && this.videos.length > 0) {
          if (this.videos[0].converting) {
            // Si el ultimo video está en conversion, no lo abro
            return;
          }
          this.FullscreenVideo(this.videos[0]);
        }
      } else {
        console.warn("Error al cargar las fotos", resVideo);
      }
    },
    async FetchPhotos(openLastPhoto = false) {

      const resPhoto = await axios.get(
        process.env.VUE_APP_API_BASE +
          `/api/userimages?where[users][equals]=${this.$store.state.user.profile.id}`
      );
      this.loading = false;
      if (resPhoto.status == 200) {
        this.photos = resPhoto.data.docs;
        this.sliderId++; // para forzar recargar
        if (openLastPhoto && this.photos.length > 0) {
          this.FullscreenImage(this.photos[0]);
        }
      } else {
        console.warn("Error al cargar las fotos", resPhoto);
      }
    },
  },
  sockets: {
    UserVideoCreated() {
      this.FetchUserMedia(true);
    },
    UserVideoEdited() {
      this.FetchUserMedia(true);
    },
  },
  async mounted() {
    this.FetchUserMedia();
    this.FetchVideos();

    let openLastPhoto = false
    if(this.$store.state.user.openGallery) {
        openLastPhoto = true;
        this.$store.commit('user/SetOpenGallery',false)
      }
    
    this.FetchPhotos(openLastPhoto);


  },
};
</script>

<style scoped lang="scss">
.backgroundThumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.card {
  overflow: hidden;

  .image {
    aspect-ratio: 1.333;
    background: linear-gradient(
      90deg,
      hsl(0deg, 0%, 86%) 25%,
      rgba(219, 219, 219, 0.5) 50%,
      hsl(0deg, 0%, 86%) 75%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.5s infinite;

    img {
      max-height: 350px;
      object-fit: contain;
    }
  }

  video {
    display: block;
    max-height: 55dvh;
    margin-inline: auto;
  }
}

.b-skeleton-item {
  aspect-ratio: 1.333;
}

.no-videos {
  & > div {
    width: 100%;
    aspect-ratio: 1.33333333;
    border-radius: 4px;
    background-color: hsl(0, 0%, 96%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
}
</style>

<style lang="scss">
.glide {
  .glide__slide {
    position: relative;
  }
  img {
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 1.333;
    object-fit: cover;
  }
  video {
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 1.333;
    background-color: hsl(0deg, 0%, 86%);
    background: linear-gradient(
      90deg,
      hsl(0deg, 0%, 86%) 25%,
      rgba(219, 219, 219, 0.5) 50%,
      hsl(0deg, 0%, 86%) 75%
    );
    background-size: 400% 100%;
  }
  .converting {
    width: 100%;
    aspect-ratio: 1.33333333;
    border-radius: 4px;
    background-color: hsl(0deg 0% 100% / 60%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    backdrop-filter: blur(8px);
  }
  .playIcon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    pointer-events: none;
  }
}
</style>
